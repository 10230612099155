<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="0" class="py-2 mb-10">
      <v-card-title>
        การเงิน > <a href="#" class="mx-2" @click="back_allinvoice()">ใบแจ้งหนี้ทั้งหมด</a> > {{data_invoice_dict.invoiceNo}}
        <v-spacer/>
      </v-card-title>
    </v-card>

    <v-card elevation="0" class="mb-10">
      <v-card-text>
        <v-row class="py-2">
          <v-col cols="12" md="6" xl="3"><b>เลขที่ invoice : </b></v-col>
          <v-col cols="12" md="6" xl="9">{{data_invoice_dict.invoiceNo}}</v-col>
        </v-row>
        <v-row class="py-2">
          <v-col cols="12" md="6" xl="3"><b> วันที่ออก : </b></v-col>
          <v-col cols="12" md="6" xl="9">{{ data_invoice_dict.invoiceDate }}</v-col>
        </v-row>
        <v-row class="py-2">
          <v-col cols="12" md="6" xl="3"><b> เวลาที่สร้าง : </b></v-col>
          <v-col cols="12" md="6" xl="9">
            {{ data_invoice_dict.createdDatetime }}
          </v-col>
        </v-row>
        <v-row class="py-2">
          <v-col cols="12" md="6" xl="3"><b>สร้างโดย : </b></v-col>
          <v-col cols="12" md="6" xl="9">{{data_invoice_dict.createdByName}}</v-col>
        </v-row>
        <v-row class="py-2">
          <v-col cols="12" md="6" xl="3"><b>สาขา : </b></v-col>
          <v-col cols="12" md="6" xl="9">{{data_invoice_dict.branchName}}</v-col>
        </v-row>
        <v-row class="py-2">
          <v-col cols="12" md="6" xl="3"><b>เลขที่อ้างอิง : </b></v-col>
          <v-col cols="12" md="6" xl="9">{{data_invoice_dict.groupName}}</v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- ที่อยู่ Branch -->
    <v-card elevation="0" class="my-0 mb-10">
      <v-card-title >
        <span>ที่อยู่ใบกำกับภาษี HQ</span>
      </v-card-title>

      <!-- แสดงที่อยู่ HQ -->
      <v-card-text >
        <v-row row >
          <v-col class="pb-0" cols="12">
            <span>บริษัท แพ็คให้สำนักงานใหญ่ จำกัด เลขผู้เสียภาษี(0125563025313) 76/19 ซอยวัดปรางค์หลวง บางม่วง บางใหญ่ นนทบุรี 11140</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- ที่อยู่ร้านค้า -->
    <v-card elevation="0" class="my-0 mb-10">
      <v-card-title >
        <span v-if="branchDisibled == true">ที่อยู่ใบกำกับภาษีสาขา</span>
        <span v-else>แก้ไขที่อยู่ใบกำกับภาษีสาขา</span>
        <v-spacer></v-spacer>
        <v-btn icon v-if="branchDisibled == true" @click="branchDisibled = !branchDisibled , rd_branch_action='1'"><v-icon size="18" >fa-edit</v-icon></v-btn>
        <v-btn icon v-else @click="branchDisibled = !branchDisibled, rd_branch_action='1' "><v-icon size="18" >fa-edit</v-icon></v-btn>
      </v-card-title>

      <!-- แสดงที่อยู่ สาขา -->
      <v-card-text v-if="branchDisibled == true">
        <v-row row >
          <v-col class="pb-0" cols="12">
            {{ data_branch_address_invoice_dict.name + ' ' + data_branch_address_invoice_dict.phone + ' ' + data_branch_address_invoice_dict.address + ' ต.' + data_branch_address_invoice_dict.tumbon + ' อ.' + data_branch_address_invoice_dict.amphoe + ' จ.' + data_branch_address_invoice_dict.province + ' ' + data_branch_address_invoice_dict.postcode }}
          </v-col>
        </v-row>
      </v-card-text>

      <!-- แก้ไขที่อยู่ สาขา -->
      <v-card-text v-else>
        <v-row>
          <v-col cols="12">
            <v-radio-group
              v-model="rd_branch_action"
              row
            >
              <v-radio
                label="เลือกจากที่มีอยู่"
                value="1"
              ></v-radio>
              <v-radio
                label="เพิ่มที่อยู่ใหม่"
                value="2"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <div v-if="rd_branch_action == '2'">
          <br>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                <h4>  ชื่อบุคคล/นิติบุคคล :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="BranchAddressInvoice.Name"
                    @change="value => BranchAddressInvoice.Name = value"
                    autocomplete="off"
                    ref="refBranchAddressInvoiceName"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                  <h4>เลขผู้เสียภาษี :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="BranchAddressInvoice.IDCardNumber"
                    @change="value => BranchAddressInvoice.IDCardNumber = value"
                    autocomplete="off"
                    ref="refBranchAddressInvoiceIDCardNumber"
                    maxlength="20"
                    outlined
                    hide-details
                    @keypress="isNumberWNoDot($event)"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                  <h4>เบอร์โทร :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="BranchAddressInvoice.Phone"
                    @change="value => BranchAddressInvoice.Phone = value"
                    autocomplete="off"
                    ref="refBranchAddressInvoicePhone"
                    maxlength="10"
                    outlined
                    hide-details
                    @keypress="isNumberWNoDot($event)"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                <h4>  ที่อยู่ :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-text-field
                    :value="BranchAddressInvoice.TextAddress"
                    @change="value => BranchAddressInvoice.TextAddress = value"
                    autocomplete="off"
                    ref="refBranchAddressInvoiceTextAddress"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row >
            <v-col class="pb-2" cols="12" md="12">
              <v-row row >
                <v-col class="pb-2" cols="12" md="3" lg="2">
                  <h4>รหัสไปรษณีย์ :</h4>
                </v-col>
                <v-col class="pb-2" cols="12" md="6" lg="4">
                  <v-autocomplete
                    v-model="BranchAddressInvoice.Address"
                    ref="refBranchAddressInvoiceAddress"
                    placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                    :items="postcode_mapping"
                    :item-text="item => item.Postcode + ' ' + item.Province + ' ' + item.District + ' ' + item.SubDistrict"
                    outlined
                    required
                    return-object
                    hide-details
                    dense

                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-autocomplete
            v-model="select_branch_ad_iv_id"
            style="max-width: 1000px"
            :items="data_branch_address_invoice_list"
            :item-text="item => item.name + ' ' + item.phone + ' ' + item.address + ' ต.' + item.tumbon + ' อ.' + item.amphoe + ' จ.' + item.province + ' ' + item.postcode"
            :item-value="item => item.id"
            dense
            filled
            outlined
          ></v-autocomplete>
        </div>

        <v-row>
          <v-col class="pb-2" cols="12" md="12">
            <v-row row >
              <v-col class="pb-2" cols="12" md="12" align="left">
                <v-btn v-if="rd_branch_action == '1'" dark color="primary" @click="AddandEditBranchAddressInvoice('update')" class="mr-1"><v-icon left >mdi-content-save</v-icon> บันทึก</v-btn>
                <v-btn v-else dark color="success" @click="AddandEditBranchAddressInvoice('add')" class="mr-1"><v-icon left >mdi-content-save</v-icon> เพิ่มและบันทึก</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- ข้อมูล invoice items -->
    <v-card elevation="0" class="my-0 mb-10">
      <v-card-title >
        <span v-if="invoiceItemDisibled == true">รายละเอียด</span>
        <span v-else>แก้ไขรายละเอียด</span>
        <v-spacer></v-spacer>
        <v-btn icon v-if="invoiceItemDisibled == true" @click="invoiceItemDisibled = !invoiceItemDisibled, calculateVAT()"><v-icon size="18" >fa-edit</v-icon></v-btn>
        <v-btn icon v-else @click="invoiceItemDisibled = !invoiceItemDisibled, calculateVAT()"><v-icon size="18" >fa-edit</v-icon></v-btn>
      </v-card-title>
      <!-- <v-divider/> -->
      <v-card-text v-if="invoiceItemDisibled == false">
        <v-row class="my-3">
          <v-col cols="12">
            <v-radio-group
              @change="calculateVAT()"
              v-model="radio_vat_type_select"
              row
            >
              <v-radio
                label="ไม่มีภาษี (No VAT)"
                value="1"
              ></v-radio>
              <v-radio
                label="รวมภาษี (VAT Included)"
                value="2"
              ></v-radio>
              <v-radio
                label="ไม่รวมภาษี (VAT Excluded)"
                value="3"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              class="elevation-0 packhai-border-table packhai-table"
              :headers="[
                { text: '', value: 'delete' ,align: 'center', sortable: false, width: '10px'},
                { text: 'ลำดับ', value: 'num' ,align: 'center', sortable: false, width: '10px' },
                { text: 'รายละเอียด', value: 'description' ,align: 'left', sortable: false, width: '300px' },
                { text: 'จำนวน', value: 'quantity' ,align: 'right', sortable: false, width: '100px' },
                { text: 'ราคา', value: 'unitPrice' ,align: 'right', sortable: false, width: '100px' },
                { text: 'ราคารวม', value: 'total_price' ,align: 'right', sortable: false, width: '100px' }
              ]"
              :items="bill_for_created_list"
              hide-default-footer
              :items-per-page="10000"
              :mobile-breakpoint="0"
            >
            <template v-slot:item.delete="{ item }">
              <v-btn v-if="bill_for_created_list.length > 1" icon color="danger" @click="delete_item(item)"><v-icon>fa-trash-alt</v-icon></v-btn>
              <v-btn v-else icon ><v-icon>fa-trash-alt</v-icon></v-btn>
            </template>
            <template v-slot:item.num="{ item }">
              {{ bill_for_created_list.indexOf(item) + 1 }}
            </template>

            <template v-slot:item.description="{ item }">
              <v-text-field
                ref="descriptionInput"
                :value="item.description"
                @change="value => item.description = value"
                autocomplete="off"
                placeholder="รายละเอียด"
                required
                outlined
                hide-details
                @blur="descriptionInput(item)"
                ></v-text-field>
            </template>
            <template v-slot:item.quantity="{ item }">
              <!-- {{ formatMoney(item.quantity) }} -->
                <v-text-field
                  ref="quantityInput"
                  :value="item.quantity"
                  @change="value => item.quantity = value"
                  autocomplete="off"
                  placeholder="จำนวน"
                  required
                  outlined
                  type="number"
                  hide-details
                  @blur="quantityInput(item)"
                ></v-text-field>
              </template>
            <template v-slot:item.unitPrice="{ item }">
                <!-- {{ formatMoney(item.unitPrice) }} -->
                <v-text-field
                  ref="priceInput"
                  :value="item.unitPrice"
                  @change="value => item.unitPrice = value"
                  autocomplete="off"
                  placeholder="ราคา"
                  type="number"
                  required
                  outlined
                  hide-details
                  @blur="priceInput(item)"
                ></v-text-field>
            </template>

            <template v-slot:item.total_price="{ item }">
                {{ formatMoney(item.total_price) }}
            </template>

            </v-data-table>
          </v-col>
          <v-col cols="12" class="my-2"> <v-btn color="info" outlined @click="add_data_bill()"> <v-icon left> fa-plus </v-icon> เพิ่มแถว </v-btn> </v-col>
        </v-row>
        <v-row justify="end">
            <v-col lg="5">
                <v-divider></v-divider>
                <v-row class="py-3">
                    <v-col>
                        ยอดรวม
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span>{{ formatMoney(this.sum_price.toFixed(2)) }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="py-3">
                    <v-col>
                      ยอดที่คำนวนภาษี
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span>{{ formatMoney(this.sum_price_vat.toFixed(2)) }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                  <v-row class="py-3">
                      <v-col>
                      ภาษีมูลค่าเพิ่ม
                      <span v-if="radio_vat_type_select == '1'">(No VAT)</span>
                      <span v-if="radio_vat_type_select == '2'">(VAT Included)</span>
                      <span v-if="radio_vat_type_select == '3'">(VAT Excluded)</span>
                      </v-col>
                      <v-col cols="4" lg="3" align="right">
                          <span>{{ formatMoney(this.sum_vat.toFixed(2)) }}</span>
                      </v-col>
                  </v-row>
                  <v-divider></v-divider>
                    <v-row class="py-3">
                        <v-col>
                        ยอดรวมทั้งหมด
                        </v-col>
                        <v-col cols="4" lg="3" align="right">
                            <span>{{ formatMoney(this.sum_all.toFixed(2)) }}</span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn dark color="primary" @click="update_iv_item()" class="mr-1"><v-icon left >mdi-content-save</v-icon> บันทึก</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row>
          <v-col cols="12">
            <v-data-table
              class="elevation-0 packhai-border-table packhai-table"
              :headers="[
                { text: 'ลำดับ', value: 'num' ,align: 'center', sortable: false, width: '10px' },
                { text: 'รายละเอียด', value: 'description' ,align: 'left', sortable: false, width: '300px' },
                { text: 'จำนวน', value: 'quantity' ,align: 'right', sortable: false, width: '100px' },
                { text: 'ราคา', value: 'unitPrice' ,align: 'right', sortable: false, width: '100px' },
                { text: 'ราคารวม', value: 'total_price' ,align: 'right', sortable: false, width: '100px' }
              ]"
              :items="bill_for_created_list"
              hide-default-footer
              :items-per-page="10000"
              :mobile-breakpoint="0"
            >
            <template v-slot:item.num="{ item }">
              {{ bill_for_created_list.indexOf(item) + 1 }}
            </template>

            <template v-slot:item.quantity="{ item }">
              {{ formatMoney(item.quantity) }}
            </template>
            <template v-slot:item.unitPrice="{ item }">
                {{ formatMoney(item.unitPrice) }}
            </template>

            <template v-slot:item.total_price="{ item }">
                {{ formatMoney(item.total_price) }}
            </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row justify="end">
            <v-col lg="5">

                <v-divider></v-divider>
                <v-row class="py-3">
                    <v-col>
                        ยอดรวม
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span>{{ formatMoney(this.sum_price.toFixed(2)) }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="py-3">
                    <v-col>
                      ยอดที่คำนวนภาษี
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span>{{ formatMoney(this.sum_price_vat.toFixed(2)) }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                  <v-row class="py-3">
                      <v-col>
                      ภาษีมูลค่าเพิ่ม
                      <span v-if="radio_vat_type_select == '1'">(No VAT)</span>
                      <span v-if="radio_vat_type_select == '2'">(VAT Included)</span>
                      <span v-if="radio_vat_type_select == '3'">(VAT Excluded)</span>
                      </v-col>
                      <v-col cols="4" lg="3" align="right">
                          <span>{{ formatMoney(this.sum_vat.toFixed(2)) }}</span>
                      </v-col>
                  </v-row>
                  <v-divider></v-divider>
                    <v-row class="py-3">
                        <v-col>
                        ยอดรวมทั้งหมด
                        </v-col>
                        <v-col cols="4" lg="3" align="right">
                            <span>{{ formatMoney(this.sum_all.toFixed(2)) }}</span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
            </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { reportService_dotnet,  branchService_dotnet, generalService_dotnet } from '@/website/global'
  import { AlertSuccess, AlertWarning, AlertError } from '@/website/global_alert'
  import { FormatInvoice, FormatOrder, formatMoney, isNumberWNoDot, set_format_date_time, format_price, format_vat_type } from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      page_loading: true,
      loading: false,


      data_invoice_dict : null,

      data_branch_address_invoice_dict: null,
      data_branch_address_invoice_list: null,

      navigete: [],

      // paginations
      page: 1,

      // ข้อมูล dialog invoice

      branchDisibled: true,
      postcode_mapping: null,

      // ออกใบกำกับภาษี branch
      branch_FullAddress_text: null,
      BranchAddressInvoice: {
        "ID":null,
        "Name": null,
        "Phone": null,
        "IDCardNumber": null,
        "TextAddress": null,
        "Address": null,
        "PostcodeMappingID": null,
        "IsDefault": true,
      },

      rd_branch_action: '1',
      select_branch_ad_iv_id: null,

      // invoice item
      invoiceItemDisibled: true,
      radio_vat_type_select: "3",

      // created bill
      bill_description: null,
      bill_quantity: null,
      bill_price:null,
      bill_for_created_list: [],

      // sum
      sum_price: 0,
      sum_price_vat:0,
      sum_vat: 0,
      sum_all: 0,

    }),
    async created() {
      this.navigete = JSON.parse(localStorage.getItem('navigete'))
      this.data_query_invoiceID = this.$route.query.id
      await this.get_postcode_mapping()
      await this.load_data()
      document.title = 'ใบแจ้งหนี้เลขที่ '+ this.data_invoice_dict.invoiceNo
      this.page_loading = false
    },
    computed: {
    },
    watch: {
    },
    methods: {
      async get_postcode_mapping () {
        let response = await axios.post(generalService_dotnet+'General/get-postcodemapping', {},{ headers: header_token})
        this.postcode_mapping = response.data
      },

      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDate (date) {
        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },


      async load_data () {
        let response = await axios.post(reportService_dotnet+'HQInvoice/get-hqinvoice-for-bill', {
          'hqInvoiceID': this.data_query_invoiceID
        },{ headers: header_token})
        this.data_invoice_dict = response.data.bill_data_invoice
        this.data_branch_address_invoice_dict = response.data.bill_branch_address_invoice.branch_add_inv
        this.data_branch_address_invoice_list = response.data.bill_branch_address_invoice.branch_add_inv_list

        for (var item of this.data_branch_address_invoice_list) {
          if(this.data_branch_address_invoice_dict.id == item.id){
            this.select_branch_ad_iv_id = item.id
            break;
          }
        }

        this.radio_vat_type_select = response.data.bill_data_invoice.vatType.toString()
        this.bill_for_created_list = response.data.bill_hqinvoice_items
        for (var item2 of this.bill_for_created_list) {
          item2['total_price'] = item2.quantity * item2.unitPrice
        }
        this.calculateVAT()
      },


      calculateTotal(item) {
        var result = 0
        if(item.RemotePrice != '' && item.RemotePrice != null){
          result += parseFloat(item.RemotePrice)
        }
        if(item.CODCharged != '' && item.CODCharged != null){
          result += parseFloat(item.CODCharged)
        }
        if(item.PackPrice != '' && item.PackPrice != null){
          result += parseFloat(item.PackPrice)
        }
        if(item.BoxPrice != '' && item.BoxPrice != null){
          result += parseFloat(item.BoxPrice)
        }
        if(item.DeliveryPrice != '' && item.DeliveryPrice != null){
          result += parseFloat(item.DeliveryPrice)
        }
        if(item.Discount != '' && item.Discount != null){
          result += parseFloat(item.Discount)
        }

        if(item.ServiceVatAmount != '' && item.ServiceVatAmount != null){
          result += parseFloat(item.ServiceVatAmount)
        }

        if(item.CODVatAmount != '' && item.CODVatAmount != null){
          result += parseFloat(item.CODVatAmount)
        }
        return result.toFixed(2)
      },

        async AddandEditBranchAddressInvoice (type) {

          if(type == 'update'){
            this.loading = true
            let res1 = await axios.post(reportService_dotnet+'HQInvoice/hqinvoice-update-branch-addressid', {
              "invoiceID": this.data_query_invoiceID,
              "branchInvoiceAddressID": this.select_branch_ad_iv_id
            },{ headers: header_token})

          }else {
            if(this.BranchAddressInvoice.Name == null || this.BranchAddressInvoice.Name == ''){
              this.$refs.refBranchAddressInvoiceName.focus()
              return
            } else if (this.BranchAddressInvoice.Phone == null || this.BranchAddressInvoice.Phone == '') {
              this.$refs.refBranchAddressInvoicePhone.focus()
              return
            } else if (this.BranchAddressInvoice.TextAddress == null || this.BranchAddressInvoice.TextAddress == '') {
              this.$refs.refBranchAddressInvoiceTextAddress.focus()
              return
            } else if (this.BranchAddressInvoice.Address == null || this.BranchAddressInvoice.Address == '') {
              this.$refs.refBranchAddressInvoiceAddress.focus()
              return
            }

            this.loading = true
            let res2 = await axios.post(branchService_dotnet+'Branch/update-branch-address-invoice', {
              "id": 0,
              "branchId": this.data_invoice_dict.branchID,
              "name": this.BranchAddressInvoice.Name,
              "idcardNumber": this.BranchAddressInvoice.IDCardNumber,
              "phone": this.BranchAddressInvoice.Phone,
              "address": this.BranchAddressInvoice.TextAddress,
              "postcodeMappingId": this.BranchAddressInvoice.Address.Id,
              "tumbon": this.BranchAddressInvoice.Address.SubDistrict,
              "amphoe": this.BranchAddressInvoice.Address.District,
              "province": this.BranchAddressInvoice.Address.Province,
              "postcode": this.BranchAddressInvoice.Address.Postcode,
              "isDefault": true,
              "isDeleted": false,
              "logo": null
            },{ headers: header_token})

            var last_insert_id ;
            for (var item of res2.data) {
              if(item.isDefault == true){
                last_insert_id = item.id ;
                break;
              }
            }

            let res3 = await axios.post(reportService_dotnet+'HQInvoice/hqinvoice-update-branch-addressid', {
              "invoiceID": this.data_query_invoiceID,
              "branchInvoiceAddressID": last_insert_id
            },{ headers: header_token})

            this.loading = false

            this.BranchAddressInvoice= {
              "ID":null,
              "Name": null,
              "Phone": null,
              "IDCardNumber": null,
              "TextAddress": null,
              "Address": null,
              "PostcodeMappingID": null,
              "IsDefault": true,
            }
          }
          this.branchDisibled = true

          this.loading = false
          await this.load_data()
        },

        add_data_bill () {
          var data_dict = {"id": (this.bill_for_created_list.length + 1) * -1, "hqInvoiceID": this.data_query_invoiceID, "description": this.bill_description, "quantity": this.bill_quantity ,"unitPrice": this.bill_price, "total_price": 0}
          this.bill_for_created_list.push(data_dict)
          this.bill_description = null
          this.bill_quantity = null
          this.bill_price = null
        },

        delete_item (item) {
          if(item.description == null || item.description == ''){
            this.bill_for_created_list.splice(this.bill_for_created_list.indexOf(item), 1)
          }else {
            Swal.fire({
              position: "top",
              icon: 'warning',
              // title: 'ยกเลิกใบแจ้งหนี้',
              text: 'ต้องการลบ ' + item.description + ' หรือไม่ !!',
              confirmButtonText: 'ยืนยัน',
              cancelButtonText: 'ยกเลิก',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              allowOutsideClick: false

            }).then((result) => {
              if (result.value) {
                this.bill_for_created_list.splice(this.bill_for_created_list.indexOf(item), 1)
                this.calculateVAT()
              }
            })
          }



        },

        async update_iv_item () {
          // description
          for (var i1 = 0; i1 < this.bill_for_created_list.length; i1++) {
            if(this.bill_for_created_list[i1].description == null || this.bill_for_created_list[i1].description == null ){
              this.$refs.descriptionInput.focus()
              return
            }
          }
          // quantity
          for (var i2 = 0; i2 < this.bill_for_created_list.length; i2++) {
            if(this.bill_for_created_list[i2].quantity == null || this.bill_for_created_list[i2].quantity <= 0 ){
              this.$refs.quantityInput.focus()
              return
            }
          }
          // unitPrice
          // for (var i3 = 0; i3 < this.bill_for_created_list.length; i3++) {
          //   if(this.bill_for_created_list[i3].unitPrice == null || this.bill_for_created_list[i3].unitPrice <= 0 ){
          //     this.$refs.priceInput.focus()
          //     return
          //   }
          // }
          for (var item1 of this.bill_for_created_list) {
            delete item1['total_price']
            item1.quantity = parseInt(item1.quantity)
            item1.unitPrice = parseFloat(item1.unitPrice)
          }
          this.loading = true
          let res = await axios.post(reportService_dotnet+'HQInvoice/edit-hqinvoice-item', {
            "invoiceID": this.data_query_invoiceID,
            "staffPackhaiID": 0,
            "vattype": parseInt(this.radio_vat_type_select) ,
            "hqInvoiceItem": this.bill_for_created_list
          },
          { headers: header_token})
          if (res.status == 200) {
            this.AlertSuccess()
            this.load_data()
            this.invoiceItemDisibled = true
          }
          this.loading = false
        },

        descriptionInput (item) {
          if(item.description == null || item.description == ''){
            this.$refs.descriptionInput.focus()
            return
          }
        },

        quantityInput (item) {
          if(item.quantity == null || item.quantity == 0){
            this.$refs.quantityInput.focus()
            return
          }

          if(item.quantity != null && item.quantity > 0 && item.unitPrice != null  ){ // && item.unitPrice > 0
            item.total_price = item.quantity * item.unitPrice
          }
          this.calculateVAT()
        },

        priceInput (item) {
          if(item.unitPrice == null){ // || item.unitPrice == 0
            this.$refs.priceInput.focus()
            return
          }

          if(item.quantity != null && item.quantity > 0 && item.unitPrice != null  ){ // && item.unitPrice > 0
            item.total_price = item.quantity * item.unitPrice
          }
          this.calculateVAT()
        },

        calculateVAT () {
          this.sum_price = 0
          this.sum_price_vat = 0
          this.sum_vat = 0
          this.sum_all = 0

          if(this.bill_for_created_list.length > 0){

            for (var i = 0; i < this.bill_for_created_list.length; i++) {
              if(this.bill_for_created_list[i].total_price != null) {
                this.sum_price += this.bill_for_created_list[i].total_price
              }

            }

            if(this.radio_vat_type_select == '1'){
              this.sum_all = this.sum_price
              this.sum_price_vat = this.sum_price
            } else if (this.radio_vat_type_select == '2') {
              this.sum_vat = ( this.sum_price * 7 ) / 107
              this.sum_price_vat = this.sum_price - this.sum_vat

              this.sum_all = this.sum_price
            } else if (this.radio_vat_type_select == '3') {

              this.sum_vat = (this.sum_price * 7)/100
              this.sum_price_vat =  this.sum_price
              this.sum_all = this.sum_vat + this.sum_price
            }

          }
        },
        back_allinvoice () {
          this.$router.push('/branch-finance-invoice')
        },

        FormatInvoice,
        FormatOrder,
        formatMoney,
        isNumberWNoDot,
        set_format_date_time,
        format_price,
        format_vat_type,
        AlertSuccess,
        AlertWarning,
        AlertError,
    }
  }
</script>

<style scoped>
  ::v-deep .v-data-table__empty-wrapper {
      display: none;
  }

  .mobile-none {
    display: ;
  }

  .pc-none {
    display: none;
  }

  @media (max-width: 599px) {
    .mobile-none {
      display: none;
    }

    .pc-none {
      display: ;
    }
  }
</style>
